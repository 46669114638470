import { UserContextService } from '../service/user-context.service';
import { AppRouterService } from '../service/app-router.service';
import { Injectable, inject } from '@angular/core';

@Injectable()
export class BookingBuddyReportGuard {
    private userContextService = inject(UserContextService);
    private router = inject(AppRouterService);

    constructor() {
    }

    public canActivate() {
        if (this.userContextService.isAdmin() ||
            this.userContextService.isSM() ||
            this.userContextService.isBB() ||
            this.userContextService.isAccounting()) {
            return true;
        }
        this.router.openAccessDeniedPage();
        return false;
    }

    public canActivateChild() {
        return this.canActivate();
    }
}
